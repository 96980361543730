import { APIErrorResponse } from 'errors/ErrorAPIResponse';
import { FetchError } from 'errors/FetchError';
import { UserManager } from 'oidc-client-ts';
import { HttpMethod } from 'types/HttpMethods';

// Flatten incase there are any nested objects in the response, makes it easy for data grid to display
const flattenObject = (obj: any) => {
  const stack = [[[], obj]];
  const result = {};

  while (stack.length) {
    const [path, obj] = stack.pop();

    Object.entries(obj).forEach(([key, value]) => {
      const newPath = [...path, key];

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        stack.push([newPath, value]);
      } else {
        result[newPath.join('.')] = value;
      }
    });
  }

  return result;
};

export const fetchWithAuth = async (
  endpoint: string,
  method: HttpMethod,
  userManager: any,
  body?: any
) => {
  const user = await userManager.getUser();
  if (!user) {
    throw new Error('User not logged in.');
  }

  const options: RequestInit = {
    method,
    headers: {
      Authorization: `Bearer ${user.access_token}`,
      Origin: `${process.env.REACT_APP_VISMA_URL}`
    }
  };

  if (body) {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(body);
  }

  const response = await fetch(endpoint, options);

  if (response.status === 401 || response.status === 403) {
    window.dispatchEvent(new CustomEvent('unauthorized'));
  } else if (!response.ok) {
    const errorBody = await response.json();
    if (errorBody.detail || errorBody.message) {
      throw new APIErrorResponse(
        errorBody.title,
        errorBody.status,
        errorBody.detail,
        errorBody.id,
        errorBody.message
      );
    } else {
      throw new FetchError(`HTTP error! status: ${response.status}`, response.status, errorBody);
    }
  }

  return response;
};

export const getDataPagination = async (
  page: number,
  page_size: number,
  endpoint: string,
  sortQuery: string,
  userManager: any
) => {
  const newEndpoint = `${endpoint}?size=${page_size}&page=${page}&sort=${sortQuery}`;
  const response = await fetchWithAuth(newEndpoint, HttpMethod.GET, userManager);

  const data: ApiResponse = await response.json();

  return {
    totalPages: data.totalPages,
    rowCount: data.totalElements,
    rows: data.content,
    page: page,
    numberOfElements: data.numberOfElements
  };
};

export const getDataPaginationSearch = async (
  page: number,
  page_size: number,
  endpoint: string,
  searchQuery: string,
  sortQuery: string,
  userManager: any
) => {
  const newEndpoint = `${endpoint}${searchQuery}&size=${page_size}&page=${page}&sort=${sortQuery}`;
  const response = await fetchWithAuth(newEndpoint, HttpMethod.GET, userManager);

  const data: ApiResponse = await response.json();

  return {
    totalPages: data.totalPages,
    rowCount: data.totalElements,
    rows: data.content,
    page: page,
    numberOfElements: data.numberOfElements
  };
};

export const getData = async <T = any,>(
  endpoint: string,
  userManager: any,
  id?: string
): Promise<T> => {
  const response = await fetchWithAuth(`${endpoint}${id ? id : ''}`, HttpMethod.GET, userManager);
  const data: T = await response.json();
  return data;
};

export const getOptions = async <T = any,>(endpoint: string, userManager: any): Promise<T[]> => {
  const user = await userManager.getUser();
  if (!user) {
    throw new Error('User not logged in.');
  }

  if (!endpoint) return;

  const response = await fetch(endpoint, {
    method: HttpMethod.GET,
    headers: {
      Authorization: `Bearer ${user.access_token}`,
      Origin: `${process.env.REACT_APP_VISMA_URL}`
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status} body: ${response.body}`);
  }

  const data = await response.json();
  return data;
};

export const postData = async <T = any,>(
  endpoint: string,
  userManager: any,
  body: any
): Promise<T> => {
  if (!endpoint) return;

  const response = await fetchWithAuth(endpoint, HttpMethod.POST, userManager, body);

  let data: T = {} as T;
  try {
    const text = await response.text();
    //Check if response body is not empty
    if (text) {
      data = JSON.parse(text);
    }
  } catch (error) {
    throw new Error('Invalid JSON');
  }
  return data;
};

export const searchUser = async (email: string, userManager: any) => {
  const endpoint = `${process.env.REACT_APP_SUMRISE_URL}/admin/users?email=${email}`;
  const response = await fetchWithAuth(endpoint, HttpMethod.GET, userManager);

  const data = await response.text().then((text) => (text ? JSON.parse(text) : {}));
  return data;
};

export const putData = async <T = any,>(
  endpoint: string,
  userManager: any,
  body: any
): Promise<T> => {
  const response = await fetchWithAuth(endpoint, HttpMethod.PUT, userManager, body);
  const contentType = response.headers.get('content-type');

  if (contentType && contentType.indexOf('application/json') !== -1) {
    const data = await response.json();
    return data;
  } else {
    return null;
  }
};

export const deleteData = async (endpoint: string, userManager: any) => {
  const response = await fetchWithAuth(endpoint, HttpMethod.DELETE, userManager);
  return response;
};

export const logout = (userManager: UserManager) => {
  userManager.getUser().then((user) => {
    if (user) {
      userManager.signoutRedirect({ id_token_hint: encodeURIComponent(user.id_token) });
    }
    localStorage.clear();
  });
};

export const postFile = async (endpoint: string, userManager: any, file: File) => {
  if (!endpoint) return;

  const formData = new FormData();
  formData.append('file', file);

  const user = await userManager.getUser();
  if (!user) {
    throw new Error('User not logged in.');
  }

  const options: RequestInit = {
    method: HttpMethod.POST,
    body: formData,
    headers: {
      Authorization: `Bearer ${user.access_token}`,
      Origin: `${process.env.REACT_APP_VISMA_URL}`
    }
  };

  const response = await fetch(endpoint, options);

  if (response.status === 401 || response.status === 403) {
    window.dispatchEvent(new CustomEvent('unauthorized'));
  } else if (!response.ok) {
    const errorBody = await response.json();
    throw new FetchError(`HTTP error! status: ${response.status}`, response.status, errorBody);
  }

  const data = ![204, 202, 200].includes(response.status) ? await response.json() : {};
  return data;
};
